
/deep/.el-button--primary{
    background:#FD4446;
    color: #fff;
    border-color: #FD4446;
}
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }

.discounts{
    /deep/.el-radio-group{
        display: flex;
        flex-direction: column;
    }
}
/deep/.ParticipateDialog{
    /deep/.cell{
        padding: 0;
        margin: 0;
    }
    /deep/.has-gutter .is-leaf{
        padding: 0;
    }
    /deep/.el-radio__label{
        display: none;
    }
    .ParticipateDialogBottom{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
/deep/.has-gutter .is-leaf{
    padding-left:40px;
}
/deep/.is-checked .el-radio__inner{
    border-color: #FD4446!important;
    background: #FD4446!important;
}
/deep/.el-radio__inner{
    width: 14px;
    height: 14px;
}
/deep/.is-checked .el-radio__label{
    color: #333333!important;
    font-size: 14px;
}
.table2{
    /deep/.el-table__header-wrapper{
        display: none;
    }
}
.shopData{
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    img{
        width: 48px;
        height: 48px;
    }
    span{
        color: #333333;
        font-size: 14px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        b{
            font-weight: 500;
            display: inline-block;
            width: 30px;
            border: 1px solid #39CD7C;
            color: #39CD7C;
            font-size: 10px;
            /*padding: 3px 6px;*/
            height: 16px;
            text-align: center;
            line-height: 16px;
        }
    }
}
.PromotionOne{
    padding: 0 20px 50px 20px;
    .PromotionOneHeader{
        display: flex;
        flex-direction: column;
        .txt{
            font-size: 18px;
        }
        span{
            font-size: 16px;
            line-height: 1;
        }
        .name{
            margin-top: 30px;
        }
        .txt2{
            margin-top: 30px;
        }
    }
    .PromotionOneTable{
        .discounts{
            cursor: pointer;
            padding: 20px;
            position: relative;
            .addStage{
                display: flex;
                align-items: center;
                color: #1E63F1;
                position: absolute;
                right: 30px;
                top: 0;
                i{
                    font-size: 16px;
                }
                span{
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }
    }
    .ParticipateIn{
        /*display: flex;*/
        /*align-items: center;*/
        margin-top: 68px;
        /deep/.el-radio-group{
        }
    }
    .ParticipateBtn{
        margin-top: 57px;
    }
}
